import React from 'react'
import Layout from '../layouts'
// import ShopCategory from "../components/ecwid/category"
import Image1 from '../images/custom-teak-name-board-examples/1.jpg'
import Image2 from '../images/custom-teak-name-board-examples/2.jpg'
import Image3 from '../images/custom-teak-name-board-examples/3.jpg'
import Image4 from '../images/custom-teak-name-board-examples/4.jpg'
import Image5 from '../images/custom-teak-name-board-examples/5.jpg'
import Image6 from '../images/custom-teak-name-board-examples/6.jpg'
import Image7 from '../images/custom-teak-name-board-examples/7.jpg'
import Image8 from '../images/custom-teak-name-board-examples/8.jpg'
import Image9 from '../images/custom-teak-name-board-examples/9.jpg'

class MoreCustomTeakNameBoardExamples extends React.Component {
	render() {
		let params = this.props.location.pathname
		return (
			<Layout
				pathname={params}
				head={{
					name: 'More Custom Teak Name Board Examples',
					meta: {
						description: 'More Custom Teak Name Board Examples',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">
									More Custom Teak Name Board Examples
								</h4>
								<div className="form-group">
									<button className="btn btn-primary btn-block blue-btn">
										Go back
									</button>
									<div className="row">
										<div className="col-md-6">
											<img
												src={Image1}
												className="img-fluid img-padding"
												alt="Gallery 1"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image2}
												className="img-fluid img-padding"
												alt="Gallery 2"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image3}
												className="img-fluid img-padding"
												alt="Gallery 3"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image4}
												className="img-fluid img-padding"
												alt="Gallery 4"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image5}
												className="img-fluid img-padding"
												alt="Gallery 5"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image6}
												className="img-fluid img-padding"
												alt="Gallery 6"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image7}
												className="img-fluid img-padding"
												alt="Gallery 7"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image8}
												className="img-fluid img-padding"
												alt="Gallery 8"
											/>
										</div>
										<div className="col-md-6">
											<img
												src={Image9}
												className="img-fluid img-padding"
												alt="Gallery 9"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default MoreCustomTeakNameBoardExamples

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
